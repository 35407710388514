@import "./header/index.scss";
@import './quizheader/quizheader.scss';

.custom__container {
    max-width: 830px;
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 35px;
    padding: 20px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.srifin__homepage {
    .app__left {
        border-radius: 29px 0px 0px 29px;

        @media (max-width:768px) {
            border-radius: 19px 19px 0px 0px;
        }
    }

    .app__right {
        border-radius: 0px 29px 29px 0px;
        background: #004B87 0% 0% no-repeat padding-box;
        height: 100%;
        color: white;
        padding: 56px 30px 0px 55px;

        @media (max-width:768px) {
            border-radius: 0px 0px 19px 19px;
            padding-bottom: 108px;
        }

        @media (max-width:600px) {
            padding: 18px 14px 0px 19px;
            padding-bottom: 108px;
        }
    }

    .app__heading {
        font: normal normal bold 45px/60px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        line-height: 50px;
    }

    .app__para {
        font: normal normal normal 18px/24px Segoe UI;
        padding-top: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        line-height: 24px;
    }

    .app__button {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;
        letter-spacing: 0px;
        color: #221F1F;
        text-transform: uppercase;
        opacity: 1;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 26px;
        font-weight: 500;
    }
}

.p-datatable {
    td {
        font-size: 12px !important;
        border-width: 1px !important;
    }

    th {
        font-size: 12px !important;
        border-width: 1px !important;
    }
}

.student-report {
    .p-datatable-wrapper {
        .p-datatable-table {
            .p-datatable-thead {
                tr {
                    th:nth-child(2) {
                        .p-column-header-content {
                            .p-column-title {
                                min-width: 200px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.s__comment__badge {
    position: absolute;
    right: -10px;
    top: -14px;
    width: 20px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #2B87D8;
}

.sidebar-fixed {
    @media (min-width:600px) {
        z-index: 300 !important;
    }
}

.header-sticky {
    @media (min-width:600px) {
        z-index: 200 !important;
    }
}

.inputbox {
    color: #7c7c7c;
    padding: 6px 10px 6px 14px;
    font-size: 14px;
    border-radius: 0.65rem;
    transition: all .3s;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, .05);
}

.inputbox::placeholder {
    color: grey !important;
}

.inputbox:focus {
    border: 1px solid blue;
    outline: blue;
}

.inputtext {
    // text-transform: uppercase;
    font-size: 0.7rem;
    color: #252525;
    letter-spacing: 0px;
    font-weight: 600;
    padding-bottom: 6px;


}

.radio-group {
    .ant-radio-wrapper {
        font-size: 11px !important;
        font-weight: 600 !important;
    }
}

.divider-border {
    height: 1px;
    margin-top: 1rem;
    content: "";
    background-color: lightgrey;
}

.status-red-color {
    background: #9b111e;
    color: white;
    padding: 7px;
    border-radius: 9px;
    width: fit-content;
}

.p-datatable::-webkit-scrollbar {
    display: none;
}

.header-main {
    .p-datatable-header {
        padding: 0 0 1rem 0 !important;
    }
}

.tab__button {
    outline: none;
    border: none;
    padding: 10px;
    color: rgb(23, 23, 97);
    background: none;
    font-size: 14px;
    border-radius: 5px 5px 0px 0px;

    &__active {
        background: #3c4b64;
        color: white;
    }

    &:hover {
        background: #d4d4d4;

    }
}

.ant-select-selection-placeholder {
    font-weight: 500 !important;
    color: grey !important;
}