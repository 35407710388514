@import './scss/style.scss';

.body {
  background-color: white;
}

.ticket-list {
  overflow-y: auto;

  th {
    vertical-align: middle;
    font-size: 12px;
    padding: 5px;
    margin: 0px;
  }

  .p-inputtext {
    font-size: 12px;
  }

  .p-progressbar-value {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.ticket-summary {
  overflow-y: auto;

  th {
    vertical-align: top;
    font-size: 12px;
    padding: 5px;
    margin: 0px;
  }

  td {
    vertical-align: top;
    font-size: 12px;
    padding: 5px;
    margin: 0px;
  }

  .p-inputtext {
    font-size: 12px;
  }

}

.cursor-pointer {
  cursor: pointer !important;
}

.loaderApp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-green-timeline {
  background-color: green;
  border-radius: 50%;
}

.ticket-details {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .margin-5 {
    margin: 0px 5px;
  }

  .tab {
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  td {
    border-bottom: 0.5px solid #dee2e6;
    padding: 10px;
    font-size: 12px;
    // text-align: center;
  }

  .tickets-type {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 9px !important;
    border-top-right-radius: 9px !important;
  }

  .bg-pink {
    background-color: #fabfcc;
    color: white;
  }

  thead {
    background-color: #dee2e6 !important;
  }

  .font-14 {
    font-size: 14px;
  }
}

.font-20 {
  font-size: 20px !important;
}

// @media screen and (max-width: 991px) {
//     .customized-timeline .p-timeline-event:nth-child(even) {
//         flex-direction: row !important;
//     }
//     .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
//         text-align: left !important;
//     }
//     .customized-timeline .p-timeline-event-opposite {
//         flex: 0;
//     }
//     .customized-timeline .p-card {
//         margin-top: 1rem;
//     }
// }

.bg-lightgrey {
  background-color: lightgrey;
  border-radius: 50%;
}

.ticket-details {
  .p-timeline-event-connector {
    height: 4px !important;
  }

  .p-timeline-event-content {
    padding: 0px !important;
    font-size: 14px;
  }

  .p-timeline-event-opposite {
    padding: 0px !important;
  }
}

.show-transaction-btn {
  background-color: white;
  border: 0.5px solid lightgrey;
}

.font-bold {
  font-weight: bold !important;
}

.text-grey {
  color: grey;
}

.p-dialog-content {
  padding: 0px !important;
}

.activity_log_th {
  font-size: 13px !important;
}

.activity_log_td {
  font-size: 12px !important;
}


.loader {

  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #eb018b;
  width: 50px;
  position: absolute;
  margin-top: 14rem;
  height: 50px;
  top: 10%;
  bottom: 4rem;
  animation: spin 2s linear infinite;
  left: 50%;

}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width:999px) {
  .actvity_log_container {
    max-width: 100% !important;
    padding: 0px 5px !important;
    overflow-x: auto;
  }
}

.activitylog_table {
  height: 80% !important;
  overflow-y: scroll !important;
}

.activitylog_table tbody td {

  padding: 8px;
}

.activity_log_dataid {

  cursor: pointer;
  background: #0d6efd;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 600;
  border-radius: 4px;
}

/* 
Over Modal For THe Logout */

.section_name_overlay {
  position: fixed;
  /* display: none;  */
  width: 100%;

  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  font-family: 'Oswald', sans-serif !important;
  padding: 20px 30px;

  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */


  visibility: hidden;
  opacity: 0;
  transform: translate(0px, 100%);
  transition: all 0.3s ease-in-out;
}

.openvediomodal {
  height: 100%;
  position: fixed;
  /* display: none;  */
  width: 100%;

  top: 0;
  left: 0;
  right: 0;
  font-family: 'Oswald', sans-serif !important;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999999999999999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */

  visibility: visible !important;
  opacity: 1 !important;
  transform: translate(0px, 0%) !important;
}

.overlaycontainer {

  background: white;
  // width: 25% !important;
  /* padding: 10px 10px; */
  margin-top: 40vh;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  padding: 0px !important;

  margin-left: auto !important;
  margin-right: auto !important;

}

.logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  font-size: 16px !important;
  font-family: 'Roboto';
  font-weight: 500;
  border-radius: 0px !important;

}


.overlaycontainer .contact_para {
  font-size: 20px !important;
  font-weight: bold;
  padding: 10px !important;
  font-family: 'Segoe UI' !important;
}

.bodyscrollhidden {
  overflow: hidden;
}


.sectionheading {
  font-size: 30px;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 600;
  text-align: center;

}

.addsectionbtn {
  width: 100%;
  margin-top: 10px;
  background-color: #dc354e !important;
  border: none !important;
  outline: none;
  /* border-radius: 24.25rem !important; */
}

.crossicon {
  float: right;
  background: white;
  padding: 0px 1px;
  /* border-radius: 50%; */
  margin-top: 3px;
  color: #dc354e;
  font-size: 23px;
  font-weight: 800 !important;
  transition: all 0.4s ease-in-out;
}

.crossicon:hover {
  transform: scale(1.5, 1.5);

}

.createsectionheading {
  font-size: 20px;
  color: #db3751;
}

.form-label {
  font-family: 'Oswald', sans-serif !important;
}

.form-control {
  display: block;
  overflow: hidden;
  width: 100%;
  /* padding: 0.375rem 0.75rem; */
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  font-family: 'Segoe UI' !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: #dc354e !important;
  appearance: none;
  /* border-radius: 24.25rem !important; */

}

.form-control:focus {
  outline: none !important;
}

/* .form-control:focus {
    border: 2px solid #966528;
  } */

/* Overlay Css Completed Here ------------------------*/
@media screen and (max-width: 999px) {
  #sectionnameoverlay {
    padding: 0px 0px !important;
  }

}

/* Overlay Css End Here ------------------------*/
.add_contact_form_div {
  width: 60% !important;
  transform: translate(0%, -42%);
  height: 80%;
  cursor: auto !important;
  overflow-y: scroll !important;
  position: relative;
}

@media screen and (max-width: 999px) {
  .add_contact_form_div {
    width: 100% !important;
    transform: translate(0%, -40%);
  }

}

.add_contact_row {
  width: 95% !important;
  padding: 20px 0px;
  margin: 0px auto !important;
}

.contact_col {
  margin-bottom: 10px !important;
}

.contact_para {
  border-bottom: 1px solid #eee !important;
}

.form-control {
  padding: 10px;
}

.contact_para i {
  border-radius: 50% !important;
  padding: 10px 12px;
  font-weight: 700;
  font-size: 20px !important;
  transition: all 0.3s ease-in-out;
  color: black !important;
  background-color: #eee !important;
  background-color: 1px solid #eee !important;
  cursor: pointer !important;
}

.contact_para i:hover {
  background-color: white !important;
}

.activity_details_label {
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600;
}

.line_height {
  line-height: 20px !important;
}

.whatsapp_send {
  background: #10b418 !important;
  color: white;
  padding: 5px 6px;
  border-radius: 50%;
  font-weight: bolder !important;
}

.whatsapp_unsend {
  background: red !important;
  color: white;
  padding: 4px 6px;
  border-radius: 50%;
  font-weight: bolder !important;
}

@media (max-width: 768px) {
  .padding_smdevice_style {
    padding: 20px 20px !important;
  }
}

.filter_row {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.margin_left {
  margin-left: 35px;
}

.input_style {
  border-radius: 50px;
  outline: none !important;
  border: 1px solid #eee;
  padding: 8px 10px;
  width: 100%;
  font-size: 14px;
  line-height: 0px !important;
  // width: 100% !important;
}

.filter_btn {
  padding: 8px 20px !important;
  border-radius: 50px !important;
  width: 100%;
  text-align: center !important;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  font-family: "Segoe UI" !important;
}

.filter_span_text {
  display: block !important;
  margin-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Segoe UI';
  font-weight: 600;
}


.filter_icon {
  margin-right: 5px;
  font-size: 12px !important;
}

.filter_cols {
  display: flex !important;
  align-items: center !important;

}

@media (max-width: 1150px) {
  .filter_row {
    flex-wrap: wrap !important;
    justify-content: space-between;
  }

  .margin_left {
    margin-left: 0px;

  }

  .filter_cols {
    width: 49% !important;
    margin-bottom: 5px;
  }

  .input_style {
    padding: 10px !important;
    width: 100% !important;
    font-size: 12px;
  }

  .mobile_margin_left {
    margin-left: 3px !important;
  }

  .filter_row .col-sm-12 {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

.p-datatable {

  th,
  td {
    padding: 0.5rem !important;
    font-size: 13px !important;
  }
}

.disable-btn {
  pointer-events: none;
}

.ant-radio-wrapper {
  font-size: 12px;
}

.p-datatable-header {
  font-size: 13px;
}

.p-datatable-wrapper {
  overflow: auto !important;
}