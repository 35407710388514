.navbar12 {
    padding: 10px 0;
    background-color: var(--white-color);
    box-shadow: 0px 8px 20px rgba(41, 99, 112, 0.06);
}

.radio-box {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    padding: 6px 14px 6px 14px;
    width: 100%;
}

.custom-btn{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* height: 100%; */
    padding: 10px !important
}

.desktop__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .srifin__logo {
        height: 45px;
    }

    .inputsearch {
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        background: #F6F8FA;
        transition: var(--transition);
        color: #869199;
        outline: 0;
        padding: 15px 25px;
        font-size: 14px;
        height: 100%;
    }

    .inputsearch::placeholder {
        font-weight: 500;
    }

    .search-box {
        margin-left: 25px;
        margin-right: 25px;
        width: 350px;
        height: 40px;
        position: relative;

        .search__icon {
            position: absolute;
            right: 26px;
            top: 28%;
            color: #fec630;
        }
    }

    .blue-color {
        background-color: #0071dc;
        color: white;
        font-size: 14px;
    }

    .header__right {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width:1050px) {
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    .header__para {
        font-weight: 500;
        color: #000;
        font-size: 14px;
    }

    .user__icon {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        border: 4px solid #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}